import { CustomerRole } from './_.dto'
import { AccountInfoDto } from './account.dto'
import { BOOKING_TYPE, BookingDto } from './booking.dto'
import { PromoteCodeStatus } from './invoice.dto'

export enum PROMOTE_USER_TYPE {
  NEW_USERS = 'New Users',
  ALL_USERS = 'All Users'
}

export enum DISCOUNT_TYPE {
  FIXED = 'fixed',
  PERCENT = 'percent'
}

export enum PromoteDateRange {
  indefinite = 'indefinite',
  _3MONTH = '3m',
  _6MONTH = '6m',
  _12MONTH = '12m',
  custom = 'custom'
}

export enum PromoCodeQtyType {
  UNLIMITED = 'UNLIMITED',
  CUSTOM = 'CUSTOM'
}

export interface PromoDto {
  id: string
  code: string
  discountType: DISCOUNT_TYPE
  discountValue: number
  selectedCategoriesOnly: boolean
  dateRangeType: PromoteDateRange
  fromDate?: string
  toDate?: string
  serviceType: BOOKING_TYPE | ''
  userType: PROMOTE_USER_TYPE
  categories: PromoteCategory[]
  maxNoOfUses: number
  customerRole: CustomerRole
  promoCodeQtyType: PromoCodeQtyType
  promoCodeQty: number
  qualityClaimed: number
}

export interface UpdatePromoBodyDto {
  code: string
  discountType: DISCOUNT_TYPE
  discountValue: number
  selectedCategoriesOnly: boolean
  dateRangeType: PromoteDateRange
  fromDate?: string
  toDate?: string
  serviceType: BOOKING_TYPE | ''
  userType: PROMOTE_USER_TYPE
  categories: PromoteCategory[]
  maxNoOfUses: number
  customerRole: CustomerRole
  promoCodeQtyType?: PromoCodeQtyType
  promoCodeQty?: number
}

export interface PromoteCategory {
  serviceCategoryId?: string
  mainCategoryId?: string
  subCategoryId?: string
  serviceId: string
}

export interface PromoCodeFilterQuery {
  offset?: number
  limit?: number
  search?: string
  status?: PromoteCodeStatus
  bookingType?: BOOKING_TYPE
  userType?: PROMOTE_USER_TYPE
  sortBy?: string
  sortType?: string
}

export interface PromoCodeUsed {
  accountId: string
  bookingId: string
  code: string
  createdAt: string
  id: string
  isDeleted: boolean
  modifiedAt: string
  promoCode: {
    categories: PromoteCategory[]
    discountType: DISCOUNT_TYPE
    discountValue: number
    selectedCategoriesOnly: boolean
  }
}

export interface PromoHistoryDto {
  id: string
  code: string
  bookingId: string
  booking: BookingDto
  account: AccountInfoDto
  isDeleted: boolean
  createdAt: string
  dateOfUsed: string
}

export interface PromoHistoryResponse {
  items: PromoHistoryDto[]
  count: number
}

export enum DISCOUNT_VALUE {
  FIXED = '$',
  PERCENT = '%'
}

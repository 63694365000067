import { SWITCH_FUNCTION } from '.'
import { COUNTRY_CODE, EmployeeModule, ResidentialType } from '../definition'

export const CONFIG_RESIDENTIAL_TYPE = {
  [ResidentialType.HDB]: {
    show: true,
    label: ResidentialType.HDB
  },
  [ResidentialType.Condo]: {
    show: true,
    label: ResidentialType.Condo
  },
  [ResidentialType.Landed]: {
    show: true,
    label: ResidentialType.Landed
  },
  [ResidentialType.Commercial]: {
    show: true,
    label: ResidentialType.Commercial
  },
  [ResidentialType.Industrial]: {
    show: false,
    label: ResidentialType.Industrial
  }
}

export const CONFIG_COUNTRY_CODE = {
  [COUNTRY_CODE._60]: {
    show: true,
    label: COUNTRY_CODE._60
  },
  [COUNTRY_CODE._62]: {
    show: true,
    label: COUNTRY_CODE._62
  },
  [COUNTRY_CODE._65]: {
    show: true,
    label: COUNTRY_CODE._65
  },
  [COUNTRY_CODE._84]: {
    show: true,
    label: COUNTRY_CODE._84
  },
  [COUNTRY_CODE._86]: {
    show: true,
    label: COUNTRY_CODE._86
  }
}

export const SERVICE_MANDATORY = {
  name: true,
  price: true,
  description: true,
  status: true,
  firstBanner: true,
  secondBanner: true,
  mainCategory: true,
  subCategory: true,
  childCategory: true
}

export const EMPLOYEE_MANDATORY = {
  name: true,
  role: true,
  contact: true,
  email: SWITCH_FUNCTION.tech_cus_required_only_contact ? false : true,
  password: true
}

export const CUSTOMER_MANDATORY = {
  name: true,
  prefferPaymentTerm: true,
  contact: true,
  email: SWITCH_FUNCTION.tech_cus_required_only_contact ? false : true,
  password: true,
  customerType: true
}

export const ADDRESS_MANDATORY = {
  name: true,
  line1: true,
  line2: false,
  postalCode: true,
  country: false,
  city: false,
  residentialType: true
}

export const EMPLOYEE_LABEL = 'technician'

export const CUSTOMER_SUB_CONTACTS = false

export const SETTING_STRIPE = false
export const SETTING_COMPLIANCE = false

export const STATUS_VENDOR = [
  { label: 'Active', value: 'active', color: '#16b682' },
  { label: 'Pending', value: 'pending', color: '#e5c340' },
  { label: 'Deactivated', value: 'deactivated', color: '#8f8e8f' }
]

export const VENDOR_MANDATORY = {
  companyName: true,
  uen: true,
  email: true,
  contact: true,
  service: true,
  proof: true,
  profile: true,
  password: true,
  gradeTier: false,
  code: true,
  contractPeriod: true
}

export const EMPLOYEE_MODULE: EmployeeModule = EmployeeModule.Technician

export const MEMBERSHIP_MANDATORY = {
  membershipTier: true,
  requiredAnnualSpend: true,
  discountedPriceOffServices: true,
  eachServiceDifferentDiscountTier: true,
  membershipTierIcon: true,
  tierBenefit: true
}

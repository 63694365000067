import axios from 'axios'
import { FilterQuery, UploadUrlDto } from '../definition'
import {
  CategoryLevel,
  CreateNewServiceCategoryRequestBodyDto,
  CreateNewServiceRequestBodyDto,
  ImportExcelServiceResponseDto,
  ListServiceCategoryResponseDto,
  ListServiceResponseDto,
  ServiceCategoryDto,
  ServiceDto,
  ServicesFilterQuery,
  UpdateServiceCategoryRequestBodyDto,
  UpdateServiceRequestBodyDto
} from '../definition/service.dto'

import { BookingDataTypeCanUploadFile } from '../definition/booking-common.dto'
import { ENV } from '../initialization'
import { putToS3, removeExifDataImage } from '../utils'
import { toast } from 'react-toastify'

const DOMAIN = ENV.BACKEND_PREFIX_URL

export const uploadServiceImage = async (
  imageFile: File,
  serviceFileId?: string,
  dataType?: BookingDataTypeCanUploadFile.banner_image | BookingDataTypeCanUploadFile.service_category_icon
) => {
  const imageNotExif = await removeExifDataImage(imageFile)
  const url = `${DOMAIN}/api/booking/get-upload-url`
  const getUrlResponse = await axios.post<UploadUrlDto>(url, {
    fileName: imageNotExif.name,
    fileId: serviceFileId,
    dataType: dataType || BookingDataTypeCanUploadFile.service_image
  })
  await putToS3(imageNotExif, getUrlResponse.data.preSignUrl)
  return getUrlResponse.data
}

export const asyncCreateService = async (data: CreateNewServiceRequestBodyDto): Promise<ServiceDto> => {
  const res = await axios.post<ServiceDto>(`${DOMAIN}/api/booking/service/create`, data)
  return res.data
}

export const asyncSaveService = async (id: string, data: UpdateServiceRequestBodyDto): Promise<ServiceDto> => {
  const res = await axios.put<ServiceDto>(`${DOMAIN}/api/booking/service/save/${id}`, data)
  return res.data
}

export const asyncDeleteService = async (id: string): Promise<void> => {
  await axios.delete(`${DOMAIN}/api/booking/service/delete/${id}`)
}

export const asyncGetService = async (id: string): Promise<ServiceDto> => {
  const response = await axios.get<ServiceDto>(`${DOMAIN}/api/booking/service/detail/${id}?includeRating=false`)
  return response.data
}

export const asyncGetServices = async (filter?: ServicesFilterQuery): Promise<ListServiceResponseDto> => {
  const url = `${DOMAIN}/api/booking/service/list`
  const response = await axios.get<ListServiceResponseDto>(url, {
    params: filter
  })
  return response.data
}

export const asyncCreateServiceCategory = async (data: CreateNewServiceCategoryRequestBodyDto): Promise<void> => {
  await axios.post(`${DOMAIN}/api/booking/service/category/create`, data)
}

export const asyncUpdateServiceCategory = async (
  id: string,
  data: UpdateServiceCategoryRequestBodyDto
): Promise<void> => {
  await axios.put(`${DOMAIN}/api/booking/service/category/save/${id}`, data)
}

export const asyncDeleteServiceCategory = async (id: string): Promise<void> => {
  await axios.delete(`${DOMAIN}/api/booking/service/category/delete/${id}`)
}

export const asyncGetServiceCategory = async (id: string): Promise<ServiceCategoryDto> => {
  const response = await axios.get<ServiceCategoryDto>(`${DOMAIN}/api/booking/service/category/detail/${id}`)
  return response.data
}

export const asyncGetServiceCategories = async (filter?: FilterQuery): Promise<ListServiceCategoryResponseDto> => {
  const url = `${DOMAIN}/api/booking/service/category/list`
  const response = await axios.get<ListServiceCategoryResponseDto>(url, {
    params: {
      ...filter,
      includeAll: filter?.level === CategoryLevel.Main ? true : undefined
    }
  })
  return response.data
}

export const asyncImportExcelService = async (file: File): Promise<ImportExcelServiceResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/service/add-service-from-excel`
  let bodyFormData = new FormData()
  bodyFormData.append('file', file)

  try {
    const res = await axios({
      method: 'post',
      url: url,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    return res.data
  } catch (error: any) {
    const errMsg = error.response?.data?.error?.message || error.message
    toast.error(errMsg)
  }
}

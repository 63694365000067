import React from 'react'
import { Select } from 'antd'

import './select-base.scss'

const { Option } = Select

export type OptionType = { value: string | number; label: string | number; disabled?: boolean }

interface ISelectBaseProps {
  value: string | undefined
  defaultValue?: string
  error?: boolean
  options: OptionType[]
  disabled?: boolean
  placeholder?: string
  allowClear?: boolean
  showSearch?: boolean
  className?: string
  onChange?: (value: string) => void
  onSelect?: (value: string) => void
  filterOption?: (inputValue: string, option?: any) => boolean
}

export const SelectBase: React.FC<ISelectBaseProps> = (props) => {
  const {
    value,
    defaultValue,
    error,
    options,
    disabled,
    placeholder,
    allowClear = false,
    showSearch = false,
    className,
    onChange,
    onSelect,
    filterOption,
    ...rest
  } = props

  return (
    <Select
      className={`select-base ${className} ${error ? 'input-error' : ''}`}
      value={value}
      defaultValue={defaultValue}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onSelect={onSelect}
      filterOption={filterOption}
      {...rest}
    >
      {(options || []).map((item) => (
        <Option key={item.value} value={item.value} disabled={item.disabled}>
          {item.label}
        </Option>
      ))}
    </Select>
  )
}

import { SWITCH_FUNCTION } from './index'
import { PaymentMethod, PaymentMethodLabel, PaymentTerm, PaymentTermLabel } from '../definition'

export const CONFIG_PREFFER_PAYMENTTERM = {
  [PaymentTerm.COD]: {
    show: false,
    label: PaymentTermLabel.COD
  },
  [PaymentTerm.CREDIT]: {
    show: true,
    label: PaymentTermLabel.CREDIT
  },
  [PaymentTerm.PPP]: {
    show: false,
    label: PaymentTermLabel.PPP
  },
  [PaymentTerm.FPP]: {
    show: true,
    label: PaymentTermLabel.FPP
  },
  [PaymentTerm.OTHER]: {
    show: false,
    label: PaymentTermLabel.OTHER
  }
}

export const CONFIG_BOOKING_PAYMENTTERM = {
  [PaymentTerm.COD]: {
    show: false,
    label: PaymentTermLabel.COD
  },
  [PaymentTerm.CREDIT]: {
    show: true,
    label: PaymentTermLabel.CREDIT
  },
  [PaymentTerm.PPP]: {
    show: true,
    label: PaymentTermLabel.PPP
  },
  [PaymentTerm.FPP]: {
    show: true,
    label: PaymentTermLabel.FPP
  },
  [PaymentTerm.OTHER]: {
    show: false,
    label: PaymentTermLabel.OTHER
  }
}

export const CONFIG_BOOKING_PAYMENTMETHOD = {
  [PaymentMethod.CASH]: {
    show: false,
    label: PaymentMethodLabel.CASH
  },
  [PaymentMethod.PAYNOW]: {
    show: true,
    label: PaymentMethodLabel.PAYNOW
  },
  [PaymentMethod.CARD]: {
    show: true,
    label: PaymentMethodLabel.CARD
  }
}

export const DEFAULT_PAYMENT_TERM = PaymentTerm.FPP

export const DEFAULT_BOOKING_PAYMENT_TERM = undefined

export const ONETIME_BOOKING_MANDATORY_FIELD = {
  customerName: true,
  contactNumber: true,
  contactNumberCode: SWITCH_FUNCTION.country_code,
  paymentTerm: true,
  email: !SWITCH_FUNCTION.tech_cus_required_only_contact,
  address1: true,
  address2: false,
  postalCode: false,
  city: false,
  country: false,
  service: true,
  residentialType: SWITCH_FUNCTION.residential_type,
  assignId: false,
  appoinmentDate: true,
  timeslot: true
}

export const CONTRACT_BOOKING_MANDATORY_FIELD = {
  customerName: true,
  contactNumber: true,
  contactNumberCode: SWITCH_FUNCTION.country_code,
  paymentTerm: true,
  email: !SWITCH_FUNCTION.tech_cus_required_only_contact,
  address1: true,
  address2: false,
  postalCode: false,
  city: false,
  country: false,
  service: true,
  residentialType: SWITCH_FUNCTION.residential_type
}

enum ContractDuration {
  Month = 'Month',
  Year = 'Year'
}

export const CONTRACT_DURATION: ContractDuration = ContractDuration.Year

import axios from 'axios'
import {
  AccountInfoDto,
  AddressType,
  CustomerAddressDto,
  CustomerDataRetention,
  FilterQuery,
  ListAccountInfoResponseDto,
  ListAddressoResponseDto,
  NewCustomerRequestBodyDto,
  UpdateCustomerAddressDto,
  UpdateCustomerRequestBodyDto,
  UploadUrlDto
} from '../definition'
import { ENV } from '../initialization'
import { putToS3, removeExifDataImage } from '../utils'

const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetListCustomers = async (data: FilterQuery): Promise<ListAccountInfoResponseDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/list`
  const response = await axios.get<ListAccountInfoResponseDto>(url, {
    params: data
  })
  return response.data
}

export const uploadAvatar = async (avatarFile: File, customerId?: string, avatarFileId?: string) => {
  const imageNotExif = await removeExifDataImage(avatarFile)
  const url = `${DOMAIN}/api/authentication/profile/get-upload-url`
  const getUrlResponse = await axios.post<UploadUrlDto>(url, {
    fileName: imageNotExif.name,
    fileId: avatarFileId,
    ownerId: customerId,
    dataType: 'avatar'
  })
  await putToS3(imageNotExif, getUrlResponse.data.preSignUrl)
  return getUrlResponse.data
}

export const asyncCreateCustomer = async (data: NewCustomerRequestBodyDto): Promise<AccountInfoDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/create`
  const rtData = await axios.post(url, data)
  return rtData.data
}

export const asyncGetCustomerDetail = async (id: string): Promise<AccountInfoDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/detail/${id}`
  const response = await axios.get(url)
  return response.data
}

export const asyncSaveCustomer = async (id: string, data: UpdateCustomerRequestBodyDto): Promise<void> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/save/${id}`
  await axios.post(url, data)
}

export const asyncDeleteCustomer = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/delete/${id}`
  await axios.delete(url)
}

export const asyncGetCustomerAddress = async (
  data: FilterQuery,
  addressType: AddressType,
  accountId: string
): Promise<ListAddressoResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/address`
  const response = await axios.get<ListAddressoResponseDto>(url, {
    params: { ...data, addressType: addressType, accountId }
  })
  return response.data
}

export const asyncCreateCustomerAddress = async (
  data: UpdateCustomerAddressDto
): Promise<CustomerAddressDto | undefined> => {
  const url = `${DOMAIN}/api/booking/address/admin`
  const rtData = await axios.post(url, data)
  return rtData.data
}

export const asyncUpdateCustomerAddress = async (
  data: UpdateCustomerAddressDto,
  addressId: string
): Promise<CustomerAddressDto | undefined> => {
  const url = `${DOMAIN}/api/booking/address/${addressId}`
  const rtData = await axios.put(url, data)
  return rtData.data
}

export const asyncDeleteCustomerAddress = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/booking/address/${id}`
  await axios.delete(url)
}

export const asyncGetDataRetention = async (): Promise<CustomerDataRetention> => {
  const url = `${DOMAIN}/api/authentication/get-retention-period`
  const response = await axios.get<CustomerDataRetention>(url)
  return response.data
}

export const asyncSaveDataRetention = async (data: CustomerDataRetention) => {
  const url = `${DOMAIN}/api/authentication/update-retention-period`
  const res = await axios.post<CustomerDataRetention>(url, data)
  return res.data
}
